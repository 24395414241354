.page-layout {
  width: 100%;
  height: 100%;
}

.page-layout .inner-section {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.page-layout .back-link {
  position: absolute;
  top: 15px;
  padding-left: 10px;
  font-weight: bold;
  text-decoration: none;
}

.page-layout .back-link::before {
  font-family: 'Material Icons';
  content: 'chevron_left';
  vertical-align: bottom;
}
