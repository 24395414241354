.header {
  background: #ffffff;
  padding: 10px 0;
}

.header .inner-header {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.header .logo-box {
  text-align: center;
}

.header .logo {
  height: 30px;
}
