@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Material+Icons');

html, body {
  font-family: 'Nunito';
  height: 100%;
  color: #545454;
}

a {
  color: #4364e9;
}

#aider-web-app-root {
  height: 100%;
}

.blue-bg {
  background: #4364e9;
}

.btn {
  border-width: 2px;
  border-radius: 50px;
  font-weight: bold;
}

.btn-outline-light {
  border-color: #ffffff;
  color: #ffffff;
}

.btn-outline-light:hover, .btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show>.btn-outline-light.dropdown-toggle {
  color: #4364e9;
  border-color: #ffffff;
  background: #ffffff;
}

.centered {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.btn-primary {
  background: none;
  border-color: #4364e9;
  color: #4364e9;
  border-width: 2px;
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background: #4364e9;
  border-color: #4364e9;
}

.btn-light {
  background: #ffffff;
  border-color: #ffffff;
  color: #4364e9;
  border-width: 2px;
}

.btn-light:hover, .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle {
  color: #4364e9;
}
