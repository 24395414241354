.payment-screen .page-section {
  padding: 0 10px;
}

.payment-screen .heading {
  font-size: 24px;
  font-weight: bold;
  margin: 1em 0;
  color: #4364e9;
}

.payment-screen .error {
  color: #ff0000;
}

.payment-screen .credit-card {
  border: 1px #cccccc solid;
  padding: 15px;
  border-radius: 10px;
}

.payment-screen .feature-list {
  border: 1px #cccccc solid;
  border-radius: 10px;
}

.payment-screen .card, .payment-screen .card-header {
  background: none;
  border: none;
}

.payment-screen .card-header {
  cursor: pointer;
  cursor: pointer;
}

.payment-screen .card-header .title {
  display: block;
  float: left;
}

.payment-screen .card-header::after {
  display: block;
  font-family: 'Material Icons';
  content: 'expand_less';
  text-align: right;
}

.payment-screen .card-header.collapsed::after {
  font-family: 'Material Icons';
  content: 'expand_more';
}

.payment-screen .card-header.no-collapse::after {
  content: '';
}

.payment-screen .sub-heading {
  font-size: 18px;
  font-weight: bold;
  margin: 1em 0;
}

.payment-screen .stripe-logo {
  float: right;
  height: 20px;
  margin-top: 1.5em;
}

.payment-screen .terms-box {
  margin-top: 2em;
  font-size: 12px;
}

.payment-screen .feature-items {
  margin: 10px;
  font-size: 13px;
  border-top: 1px dotted #cccccc;
}

.payment-screen .spinner-border-sm {
  margin-right: 5px;
  vertical-align: top;
  margin-top: 4px;
}

.payment-screen .success-alert {
  margin: 2em 0;
}

.payment-screen .sub-section {
  border-top: 1px dotted #cccccc;
  padding-top: 1em;
}