.landing-page  {
  background: #4364e9;
  color: #ffffff;
}

.landing-page .card {
  border: none;
}

.landing-page .heading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 1em 0;
}

.landing-page .sub-heading {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 1em 0;
}

.landing-page .card, .landing-page .card-header {
  background: none;
}

.landing-page .card-header {
  font-weight: bold;
  cursor: pointer;
  border-bottom: 4px dotted #3b59d1;
  cursor: pointer;
}

.landing-page .card-header .title {
  display: block;
  float: left;
}

.landing-page .card-header::after {
  display: block;
  font-family: 'Material Icons';
  content: 'expand_less';
  text-align: right;
  font-size: 20px;
}

.landing-page .card-header.collapsed::after {
  font-family: 'Material Icons';
  content: 'expand_more';
}

.landing-page .card-header.no-collapse::after {
  content: '';
}

.landing-page .card-body {
  font-size: 13px;
  line-height: 1.2em;
  background: rgb(59,89,209, 0.5);
  margin-top: -2px;
}

.landing-page .take-me-back {
  margin: 2em 0;
  text-align: center;
}

.landing-page .take-me-back .btn-link {
  color: #13dfe0;
  text-decoration: underline;
}

.landing-page .button-box {
  text-align: center;
}

.landing-page .subscription-status {
  position: relative;
}

.landing-page .list-group-item {
  background: none;
  font-size: 13px;
  display: flex;
}

.landing-page .list-group-item .icon {
  background: none;
  font-size: 18px;
  display: flex;
  flex: 0 0 24px;
}

.landing-page .subscribe-now {
  height: 180px;
  padding: 30px 0;
  background: #4364e9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  border-bottom: 4px dotted #3b59d1;
}

.landing-page .subscribe-now.sticky {
  position: fixed;
}

.landing-page .feature-list {
  padding-top: 180px;
}
