.payment-result {
  background: #4364e9;
  color: #ffffff;
}

.payment-result .page-section {
  padding: 0 10px;
}

.payment-result .title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 1em 0;
}

.payment-result .button-box {
  text-align: center;
  margin-top: 3em;
}
