.error-page {
  background: #4364e9;
  color: #ffffff;
}

.error-page .page-section {
  padding: 0 10px;
}

.error-page .title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 1em 0;
}

.error-page .message {
  text-align: center;
  font-size: 21px;
}

.error-page .button-box {
  text-align: center;
  margin-top: 3em;
}
