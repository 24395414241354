@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css?family=Material+Icons);

.header {
  background: #ffffff;
  padding: 10px 0;
}

.header .inner-header {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.header .logo-box {
  text-align: center;
}

.header .logo {
  height: 30px;
}

.page-layout {
  width: 100%;
  height: 100%;
}

.page-layout .inner-section {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.page-layout .back-link {
  position: absolute;
  top: 15px;
  padding-left: 10px;
  font-weight: bold;
  text-decoration: none;
}

.page-layout .back-link::before {
  font-family: 'Material Icons';
  content: 'chevron_left';
  vertical-align: bottom;
}

.landing-page  {
  background: #4364e9;
  color: #ffffff;
}

.landing-page .card {
  border: none;
}

.landing-page .heading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 1em 0;
}

.landing-page .sub-heading {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 1em 0;
}

.landing-page .card, .landing-page .card-header {
  background: none;
}

.landing-page .card-header {
  font-weight: bold;
  cursor: pointer;
  border-bottom: 4px dotted #3b59d1;
  cursor: pointer;
}

.landing-page .card-header .title {
  display: block;
  float: left;
}

.landing-page .card-header::after {
  display: block;
  font-family: 'Material Icons';
  content: 'expand_less';
  text-align: right;
  font-size: 20px;
}

.landing-page .card-header.collapsed::after {
  font-family: 'Material Icons';
  content: 'expand_more';
}

.landing-page .card-header.no-collapse::after {
  content: '';
}

.landing-page .card-body {
  font-size: 13px;
  line-height: 1.2em;
  background: rgb(59,89,209, 0.5);
  margin-top: -2px;
}

.landing-page .take-me-back {
  margin: 2em 0;
  text-align: center;
}

.landing-page .take-me-back .btn-link {
  color: #13dfe0;
  text-decoration: underline;
}

.landing-page .button-box {
  text-align: center;
}

.landing-page .subscription-status {
  position: relative;
}

.landing-page .list-group-item {
  background: none;
  font-size: 13px;
  display: flex;
}

.landing-page .list-group-item .icon {
  background: none;
  font-size: 18px;
  display: flex;
  flex: 0 0 24px;
}

.landing-page .subscribe-now {
  height: 180px;
  padding: 30px 0;
  background: #4364e9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  border-bottom: 4px dotted #3b59d1;
}

.landing-page .subscribe-now.sticky {
  position: fixed;
}

.landing-page .feature-list {
  padding-top: 180px;
}

.payment-screen .page-section {
  padding: 0 10px;
}

.payment-screen .heading {
  font-size: 24px;
  font-weight: bold;
  margin: 1em 0;
  color: #4364e9;
}

.payment-screen .error {
  color: #ff0000;
}

.payment-screen .credit-card {
  border: 1px #cccccc solid;
  padding: 15px;
  border-radius: 10px;
}

.payment-screen .feature-list {
  border: 1px #cccccc solid;
  border-radius: 10px;
}

.payment-screen .card, .payment-screen .card-header {
  background: none;
  border: none;
}

.payment-screen .card-header {
  cursor: pointer;
  cursor: pointer;
}

.payment-screen .card-header .title {
  display: block;
  float: left;
}

.payment-screen .card-header::after {
  display: block;
  font-family: 'Material Icons';
  content: 'expand_less';
  text-align: right;
}

.payment-screen .card-header.collapsed::after {
  font-family: 'Material Icons';
  content: 'expand_more';
}

.payment-screen .card-header.no-collapse::after {
  content: '';
}

.payment-screen .sub-heading {
  font-size: 18px;
  font-weight: bold;
  margin: 1em 0;
}

.payment-screen .stripe-logo {
  float: right;
  height: 20px;
  margin-top: 1.5em;
}

.payment-screen .terms-box {
  margin-top: 2em;
  font-size: 12px;
}

.payment-screen .feature-items {
  margin: 10px;
  font-size: 13px;
  border-top: 1px dotted #cccccc;
}

.payment-screen .spinner-border-sm {
  margin-right: 5px;
  vertical-align: top;
  margin-top: 4px;
}

.payment-screen .success-alert {
  margin: 2em 0;
}

.payment-screen .sub-section {
  border-top: 1px dotted #cccccc;
  padding-top: 1em;
}
.payment-result {
  background: #4364e9;
  color: #ffffff;
}

.payment-result .page-section {
  padding: 0 10px;
}

.payment-result .title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 1em 0;
}

.payment-result .button-box {
  text-align: center;
  margin-top: 3em;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #4364e9;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.error-page {
  background: #4364e9;
  color: #ffffff;
}

.error-page .page-section {
  padding: 0 10px;
}

.error-page .title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 1em 0;
}

.error-page .message {
  text-align: center;
  font-size: 21px;
}

.error-page .button-box {
  text-align: center;
  margin-top: 3em;
}

html, body {
  font-family: 'Nunito';
  height: 100%;
  color: #545454;
}

a {
  color: #4364e9;
}

#aider-web-app-root {
  height: 100%;
}

.blue-bg {
  background: #4364e9;
}

.btn {
  border-width: 2px;
  border-radius: 50px;
  font-weight: bold;
}

.btn-outline-light {
  border-color: #ffffff;
  color: #ffffff;
}

.btn-outline-light:hover, .btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show>.btn-outline-light.dropdown-toggle {
  color: #4364e9;
  border-color: #ffffff;
  background: #ffffff;
}

.centered {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.btn-primary {
  background: none;
  border-color: #4364e9;
  color: #4364e9;
  border-width: 2px;
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background: #4364e9;
  border-color: #4364e9;
}

.btn-light {
  background: #ffffff;
  border-color: #ffffff;
  color: #4364e9;
  border-width: 2px;
}

.btn-light:hover, .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle {
  color: #4364e9;
}

